<template>
  <case-view v-bind:dataTypeName="'aragDataType'" v-bind:insurance="'ARAG'" v-bind:permission="'ManagerRechtsschutz'" v-bind:rebindPermission="'ManagerRechtsschutz'" v-bind:dataType="'ManagerRechtsschutz'" v-bind:name="'Manager Rechtsschutz Fälle'" v-bind:controllerURL="controllerURL" v-bind:allowDataEdit="true"/>
</template>

<script>
import {getApiURL} from "@/configBuilder";
import caseView from "@/components/Case/CaseView";

export default {
  name: "ManagerRechtsschutzView",
  components: { caseView },
  data: () => ({
    controllerURL: getApiURL("AragData")
  }),
}
</script>

<style scoped>

</style>
